import { styled } from '@mui/material/styles';

import {
    Paper,
    Skeleton,
} from '@mui/material';

export default styled(Paper)(({ size=60, highlighted=false, onClick, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    position: 'relative',
    transition: 'all .09s linear',

    width: size,
    height: size * 1.33,
    border: `0.1em solid ${ theme.palette.primary.main }`,

    backgroundColor: !!highlighted ? theme.palette.primary.main : theme.palette.common.white,
    color: !!highlighted ? theme.palette.common.white : theme.palette.primary.main,

    fontSize: `${ size/30 }rem`,

    ...( !!onClick ? {
        cursor: 'pointer',
        top: !!highlighted ? '-.75rem' : 0,
        '&:hover': !!highlighted ? '' : {
            backgroundColor: theme.palette.primary.hover,
            top: '-.25rem',
        },
    } : {})

}))

export const Placeholder = ({ size=60, highlighted=false }) => <Skeleton
    variant="rounded"
    width={ size }
    height={ size * 1.33 }
    sx={theme => !!highlighted ? ({ bgcolor: theme.palette.primary.main }) : {} }
/>
