'use client'

import React from 'react'
import { API } from '../../repositories'

import FormDialog from './login.js'

const actions = {
    SET: 'user/set',
    UNSET: 'user/unset'
}

const reducer = (state = false, action) => {
    switch (action.type) {
        case actions.SET:
            return action.user
        case actions.UNSET:
            return false
        default:
            return state
    }
}

const User = React.createContext()
User.displayName = 'User'

export const useUser = () => React.useContext(User)
export const UserProvider = ({ children }) => {
    const [user, dispatch] = React.useReducer(reducer, false)
    const [needsLogin, setNeedsLogin] = React.useState(false)

    React.useEffect(() => {
        API.Auth.check().then(session => {
            if (!session || session.error) return setNeedsLogin(true)
            !session.error && dispatch({ type: actions.SET, user: session }) 
        })
    }, [])

    React.useEffect(() => {
        if (!user) return
        setNeedsLogin(false)
    }, [user])

    const handleLogin = (user) => {
        dispatch({ type: actions.SET, user }) 
    }

    if (!user && !needsLogin) return null
    if (needsLogin) return <FormDialog onLogin={ handleLogin }/>
    return (
        <User.Provider value={{ user }}>
            { children }
        </User.Provider>
    )
}
