import {
    createBrowserRouter,
    RouterProvider,
    Navigate,
} from 'react-router-dom'

import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'
import {
    Box,
    CssBaseline,
} from '@mui/material';

import { UserProvider } from './contexts/user';

import { routes as ScrumSession } from './pages/scrumSession'

const router = createBrowserRouter([
    {
        path: "/",
        // element: <LandingPage/>,
        element: <Navigate to={ '/room/create' }/>,
    },
    {
        path: "/room",
        ...ScrumSession
    },
]);


function App() {
    return (
        <ThemeProvider theme={ theme }>
            <CssBaseline />
            <Box
                id="hero"
                sx={{
                    width: '100%',
                    backgroundImage: 'linear-gradient(180deg, #CEE5FD, #FFF)',
                    backgroundSize: '100% 20%',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <UserProvider>
                    <RouterProvider router={ router }/>
                </UserProvider>
            </Box>
        </ThemeProvider>
    );
}

export default App;
