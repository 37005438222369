import {
    Stack,
    Tooltip,
    Avatar,
    Typography,
    Badge,
} from '@mui/material';

import Card, { Placeholder as CardPlaceholder } from '../card'

import GradeIcon from '@mui/icons-material/Grade';

const Participant = ({ member, selection, isHost, isMasked }) => {
    return (
        <Stack key={ member.id } direction='column' alignItems="center" spacing={ 1 }>
            { isHost ? (
                <Tooltip title={ `${member.name} is hosting the session.` } placement='top'>
                    <Badge
                        badgeContent={<GradeIcon sx={{ fontSize: 'small' }}/>}
                        color='secondary'
                    >
                        <Avatar
                            sx={ theme => ({ bgcolor: (isMasked || selection) ? theme.palette.primary.main : theme.palette.grey[500] }) }
                            alt="Remy Sharp"
                            src="/broken-image.jpg"
                        >
                            { member.name[0] }
                        </Avatar>
                    </Badge>
                </Tooltip>
            ) : (
                <Avatar
                    sx={ theme => ({ bgcolor: (isMasked || selection) ? theme.palette.primary.main : theme.palette.grey[500] }) }
                    alt="Remy Sharp"
                    src="/broken-image.jpg"
                >
                    { member.name[0] }
                </Avatar>
            )}

            <Typography>
                { member.name }
            </Typography>


            { isMasked ? (
                selection ? <Card highlighted="true"/> : <CardPlaceholder/>
            ) : (
                !!selection && <Card> { selection } </Card>
            ) }
        </Stack>
    )
}
export default Participant
