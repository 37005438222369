import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { API } from '../../repositories'

let once = false
const CreateSession = (params) => {
    const navigate = useNavigate();

    React.useEffect(() => {
        if (once) return
        once = true
        API.Room.create().then(room => {
            if(room.error) return navigate(`/error`)

            navigate(`/room/${room.id}`)
        })
    })
    return null
}

export const route = {
    path: "create",
    element: <CreateSession/>,
}
