/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { io } from "socket.io-client";
import config from '../../config.js'

import { useUser } from '../../contexts/user';


// ROOM DOMAIN

const RoomActions = {
    USER_JOIN: 'room/user/join',
    TEAM_SET: 'room/user/join',
}

// export const UserJoinAction = (user) => ({ type: RoomActions.USER_JOIN, user })
export const TeamSetAction = (team) => ({ type: RoomActions.TEAM_SET, team })

const RoomReducer = (state = false, action) => {
    switch (action.type) {
        case RoomActions.TEAM_SET:
            return { ...state, team: action.team }
        case RoomActions.USER_JOIN:
            return { ...state, team: [
                ...state.team,
                action.user
            ]}
        default:
            return state
    }
}

// ===============


// ROUND DOMAIN

const RoundActions = {
    SET: 'room/round/set',
    SELECT_CARD: 'room/round/select',
    REVEAL: 'room/round/reveal',
}

export const SetRoundAction = (round) => ({
    type: RoundActions.SET,
    round
})

export const SelectCardAction = (user, card) => ({
    type: RoundActions.SELECT_CARD,
    user,
    card
})

export const RevealAction = () => ({ type: RoundActions.REVEAL })

const RoundReducer = (state = false, action) => {
    switch (action.type) {
        case RoundActions.SET:
            return action.round
        case RoundActions.SELECT_CARD:
            if (state.revealed) return state
            return ({
                ...state,
                votes: {
                    ...Object.keys(state.votes).reduce((l, c) => {
                        if (c === action.user) return l
                        return { ...l, [c]: state.votes[c] }
                    }, {}),
                    ...( action.card ? { [action.user]: action.card } : {} )
                }
            })

        case RoundActions.REVEAL:
            return ({ ...state, revealed: true })
        default:
            return state
    }
}

// ===============


// CONTEXT

const Room = React.createContext()
Room.displayName = 'Room'

export const useRoom = (id) => React.useContext(Room)

const socket = io(config.socket.host, { path: config.socket.path })

let once = true
export const RoomProvider = ({ room:roomData, children }) => {
    const { user } = useUser()
    const [isConnecting, setIsConnecting] = React.useState(false)
    const [connected, setConnected] = React.useState(false)
    const [room, roomDispatch] = React.useReducer(RoomReducer, roomData)
    const [round, roundDispatch] = React.useReducer(RoundReducer, false)

    React.useEffect(() => {
        if (!once) return
        once = false
        console.log('bindings')
    
        socket.on('room', ({ id, team }) => {
            roomDispatch(TeamSetAction(team))
            setConnected(room.id)
            setIsConnecting(false)
        })

        socket.on('round', (round) => {
            roundDispatch(SetRoundAction(round))
        })

        socket.on('card-played', ({ user, card }) => {
            roundDispatch(SelectCardAction(user, card))
        })
    }, [])

    const handlePlayCard = (card) => {
        socket.emit('action:play-card', { round: round.id, user: user.id, card })
    }

    const handleReveal = () => {
        socket.emit('action:reveal', { round: round.id, user: user.id })
    }

    const handleNewRound = () => {
        socket.emit('action:new-round', { room: room.id, user: user.id })
    }

    React.useEffect(() => {
        if (!room || isConnecting) return false

        setIsConnecting(s => {
            if (s || room.id === connected) return s
            socket.emit('action:join', { room: room.id, user })
            return true
        })
    }, [room])

    if (!connected) return null
    return (
        <Room.Provider value={{
            room,
            round,
            selected: !!round && round.votes[user.id],

            selectCard: handlePlayCard,
            reveal: handleReveal,
            newRound: handleNewRound,
        }}>
            { children }
        </Room.Provider>
    )
}
