import config from '../config.js'

const request = async (url, method, body) => {
    const req = await fetch(url, {
        method,
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        ...( body ?  { body: JSON.stringify(body) } : {})
    })
    return req.json()
}

export const API = {
    Auth: {
        check: () => request(`${ config.api }/auth/check`, 'get'),
        createSession: (name) => request(`${ config.api }/auth/session`, 'post', { name }),
    },
    Room: {
        create: () => request(`${ config.api }/room`, 'post'),
        get: (id) => request(`${ config.api }/room/${id}`, 'get'),
    },
}
