import * as React from 'react';
import { API } from '../../repositories'

import {
    Stack,
    Avatar,
    Button,
    TextField,
    Box,
    Typography,
    Container,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright Scrum.it © '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const TemporaryIdentity = ({ onLogin }) => {
    return <Box component="form" onSubmit={ async (event) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        const name = data.get('name')
        const session = await API.Auth.createSession(name)
        if (!session.error) onLogin(session)
    }} noValidate sx={{ mt: 1 }}>
        <TextField
            margin="normal"
            fullWidth
            id="name"
            label="User name"
            name="name"
        />

        <Button
            type="submit"
            fullWidth
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
        >
            Set my Username
        </Button>
    </Box>
}


export default function SignIn({ onSubmit, onLogin }) {

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   console.log({
  //     email: data.get('email'),
  //     password: data.get('password'),
  //   });
  // };

  return (
      <Container component="main" maxWidth="xs">
        <Stack alignItems="center" pt={ 8 } mb={ 2 } spacing={ 2 }>

          <Avatar sx={{ bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>

          <Typography component="h1" variant="h5">
            Welcome stranger
          </Typography>


          <Typography align="center">
            Scrum.it is free and anonymous, but you need a name to create or join scrum rooms anyways.
          </Typography>

        <TemporaryIdentity onLogin={ onLogin }/>

        {/*   <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}> */}
        {/*     <TextField */}
        {/*       margin="normal" */}
        {/*       required */}
        {/*       fullWidth */}
        {/*       id="email" */}
        {/*       label="Email Address" */}
        {/*       name="email" */}
        {/*       autoComplete="email" */}
        {/*       autoFocus */}
        {/*     /> */}
        {/*  */}
        {/*     <TextField */}
        {/*       margin="normal" */}
        {/*       required */}
        {/*       fullWidth */}
        {/*       name="password" */}
        {/*       label="Password" */}
        {/*       type="password" */}
        {/*       id="password" */}
        {/*       autoComplete="current-password" */}
        {/*     /> */}
        {/*  */}
        {/*     <Button */}
        {/*       type="submit" */}
        {/*       fullWidth */}
        {/*       variant="contained" */}
        {/*       sx={{ mt: 3, mb: 2 }} */}
        {/*     > */}
        {/*       Sign In */}
        {/*     </Button> */}
        {/*     <Grid container> */}
        {/*       <Grid item xs> */}
        {/*         <Link href="#" variant="body2"> */}
        {/*           Forgot password? */}
        {/*         </Link> */}
        {/*       </Grid> */}
        {/*       <Grid item> */}
        {/*         <Link href="#" variant="body2"> */}
        {/*           {"Don't have an account? Sign Up"} */}
        {/*         </Link> */}
        {/*       </Grid> */}
        {/*     </Grid> */}
        {/*   </Box> */}
        </Stack>

        {/* <Divider>Or join with no account</Divider> */}
        {/* <TemporaryIdentity onLogin={ onLogin }/> */}

        <Copyright sx={{ mt: 12 }} />
      </Container>
  );
}
