import Card from '../card'

import { Box } from '@mui/material';

const Hand = ({ items, selected, onSelect }) => {
    return (
        <Box
            sx={{
                position: 'absolute',
                bottom: '3rem',
                width: '100%',
                overflowX: 'auto',
            }}
        >
            <Box
                sx={{
                    width: 'max-content',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '.75rem',
                    padding: '2rem',
                    margin: 'auto',
                }}
            >
                { items.map(card => <Card
                    highlighted={ selected === card ? 'true' : ''}
                    key={ card }
                    onClick={ () => onSelect(card) }
                >
                    { card }
                </Card>) }
            </Box>
        </Box>
    )
}
export default Hand
