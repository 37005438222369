const config = {
    production: {
        host: 'https://scrumplanning.app',
        api: 'https://scrumplanning.app/api',
        socket: {
            host: 'scrumplanning.app',
            path: '/api/socket',
        }
    },
    development: {
        host: 'http://localhost:3000',
        api: 'http://localhost:3001',
        socket: {
            host: 'localhost:3001',
            path: '/socket',
        }
    }
}

export default config[process.env.NODE_ENV]
