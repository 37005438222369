import { createTheme } from '@mui/material/styles';
const defaultTheme = createTheme();

export default createTheme({
    ...defaultTheme,
    palette: {
        ...defaultTheme.palette,
        primary: {
            ...defaultTheme.palette.primary,
            main: '#63aaff',
            hover: '#ebf4ff'
        }
    }
});
