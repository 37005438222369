import * as React from 'react'
import { useLoaderData, Await } from 'react-router-dom'

import {
    Box,
    CircularProgress,
} from '@mui/material';

const Spinner = () => <Box sx={{ position: 'relative' }}>
    <CircularProgress
        variant="determinate"
        sx={{ color: (theme) => theme.palette.grey[200] }}
        size={ 80 }
        thickness={4}
        value={100}
    />

    <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
            color: (theme) => theme.palette.primary.main,
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
        }}
        size={80}
        thickness={4}
    />
</Box>


const Loading = ({ element, children }) => {
    const { promise } = useLoaderData()

    return <React.Suspense fallback={ <Spinner/> } >
        <Await resolve={ promise } errorElement={ <h1>ERROR</h1> }>
            { data => element ? element(data) : children(data) }
        </Await>
    </React.Suspense>
}
export default Loading
