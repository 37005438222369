import * as React from 'react'
import { defer } from 'react-router-dom'
import QRCode from 'react-qr-code'

import {
    Dialog,
    DialogTitle,
    DialogContent,

    Alert,
    Badge,
    Box,
    Container,
    Typography,
    Button,
    Stack,
    Divider,
} from '@mui/material';


import CelebrationIcon from '@mui/icons-material/Celebration';
import HeightIcon from '@mui/icons-material/Height';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import AlignVerticalTopIcon from '@mui/icons-material/AlignVerticalTop';

import QrCode2Icon from '@mui/icons-material/QrCode2';

import Loading from '../../components/loading'
import Card from './components/card'
import CardHand from './components/cardHand'
import Participant from './components/participant'

import { useUser } from '../../contexts/user';
import { RoomProvider, useRoom } from '../../contexts/room'

import { API } from '../../repositories'
import config from '../../config.js'

const loader = async ({ params }) => {
    const promise = API.Room.get(params.roomId)
    return defer({ promise })
}

const Admin = ({ round }) => {
    const { newRound, reveal } = useRoom()

    if (!round) return (
        <>
            <Typography variant="h4">Gather your team and start voting.</Typography>
            <Box>
                <Button variant="contained" size="large" color="primary" onClick={ newRound }>
                    <b id="new_round">Start</b>
                </Button>
            </Box>
        </>
    )

    if (round.revealed) return (
        <>
            <Typography variant="h4">Vote again!</Typography>
            <Box>
                <Button variant="contained" size="large" color="primary" onClick={ newRound }>
                    <b id="new_round">New round</b>
                </Button>
            </Box>
        </>
    )

    return (
        <>
            <Typography variant="h4">Reveal the results to the team.</Typography>
            <Box>
                <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={ Object.keys(round.votes).length === 0}
                    onClick={ reveal }
                >
                    <b>Reveal votes</b>
                </Button>
            </Box>
        </>
    )
}

const Member = ({ round }) => (<Typography variant="h4">
    { (!round || round.revealed)
        ? 'Wait for the voting to start.'
        : 'Pick your cards.'
    }
</Typography>)

const Stats = ({ average, votes }) => {
    const keys = Object.keys(votes)
    const cards = keys.reduce((l, k) => {
        if (!l[votes[k]]) l[votes[k]] = 0
        l[votes[k]]++
        return l
    }, {})

    const { max, min, total } =  keys.reduce((l, c) => {
        l.total += votes[c]
        return {
            ...l,
            max: Math.max(l.min, votes[c]),
            min: Math.min(l.max, votes[c]),
        }
    }, { max: 999, min: 0, total: 0})
    const avg = total / keys.length 
    const consensus = Object.keys(cards).length === 1

    const isOverAverage = avg >= average * .9
    const isWideSpread = (max - min) > min
    const hasAlert = isOverAverage || isWideSpread

    return (
        <Stack align="center" spacing={ 3 }>
            <Stack direction="row" justifyContent="center" spacing={ 1 }>
                { Object.keys(cards).map(k => {
                    return <Badge key={ k } badgeContent={ cards[k] } color="primary">
                        <Card size={ 40 } >{ k }</Card>
                    </Badge>
                })}

            </Stack>

            <Stack direction="row" justifyContent="center" mt={ 2 } spacing={ 1 }>

                <Box align="center">
                    <AlignVerticalBottomIcon color={ consensus && !hasAlert ? 'success' : '' }/>
                    <Typography variant="h6" color={ consensus && !hasAlert ? 'green' : '' }>
                        <b>{ min }</b>
                    </Typography>
                </Box>

                <Divider orientation="vertical" variant="middle" flexItem />

                <Box align="center">
                    <AlignVerticalCenterIcon color={ isOverAverage ? 'primary': (consensus ? 'success' : '') } />
                    <Typography  color={ isOverAverage ? 'primary': (consensus ? 'green' : '') } variant="h6">
                        <b>{ avg }</b>
                    </Typography>
                </Box>

                <Divider orientation="vertical" variant="middle" flexItem />

                <Box align="center">
                    <AlignVerticalTopIcon color={ consensus && !hasAlert ? 'success' : '' }/>
                    <Typography variant="h6" color={ consensus && !hasAlert ? 'green' : '' }>
                        <b>{ max }</b>
                    </Typography>
                </Box>

                <Divider orientation="vertical" variant="middle" flexItem />

                <Box align="center">
                    <HeightIcon color={ isWideSpread ? "primary" : (consensus && !hasAlert ? 'success' : '')}/>
                    <Typography color={ isWideSpread ? "primary": (consensus && !hasAlert ? 'green' : '') } variant="h6">
                        <b>{ max - min }</b>
                    </Typography>
                </Box>
            </Stack>

            {
                (isWideSpread)
                    ? <Alert severity="info">
                        Your vote spread is really wide. Are you sure the task requirements are clear enough?
                    </Alert>
                    : (
                        (isOverAverage)
                            ? <Alert severity="info">
                                Your average might be a little high. You probably should consider splitting your task.
                            </Alert>
                            : (consensus
                                ? <Alert icon={<CelebrationIcon/>} severity="success">
                                    Yayy!! Your team reached a consensus.
                                </Alert>
                                : ''
                            )
                    )
            }
        </Stack>
    )
}

const Round = () => {
    const {
        room,
        round,

        selected,
        selectCard,
    } = useRoom()

    const { user } = useUser()
    const isOwner = user.id === room.owner

    const [showQRCode, setShowQRCode] = React.useState(false)

    const handleSelectCard = (card) => selectCard(selected === card ? 0 : card)

    return (
        <>

            <Stack direction='column' spacing={ 4 }>
                { round.revealed 
                    ? <Stats average={ room.set.reduce((l, c) => l + c,0) / room.set.length } votes={ round.votes }/>
                    : (
                        <Stack direction='row'
                            justifyContent="center"
                            flexWrap="wrap"
                            spacing={ 3 }
                        >
                            { room.team.map(member => <Participant
                                key={ member.id }
                                member={ member }
                                selection={ round && round.votes[member.id] }
                                isHost={ room.owner === member.id }
                                isMasked={ !round.revealed }
                            />) }
                        </Stack>
                    )
                }

                <Stack align="center" spacing={ 2 }>
                    { isOwner ? <Admin round={ round }/> : <Member round={ round }/> }
                </Stack>
            </Stack>

            { (!!round && !round.revealed) && <CardHand items={ room.set } selected={ selected } onSelect={ handleSelectCard }/> }

            <Box p={ 2 } sx={{ position: 'fixed', top: 0, right: 0 }}>
                
                <Dialog
                    open={ showQRCode }
                    onClose={ () => setShowQRCode(false) }
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" align="center">
                        Scan to join the room
                    </DialogTitle>
                    <DialogContent align="center">
                        <QRCode value={ `${ config.host }/room/${room.id}` }/>
                    </DialogContent>
                </Dialog>

                {/* <IconButton size="medium" color="primary" onClick={ () => setShowQRCode(true) }> */}
                {/*     <QrCode2Icon /> */}
                {/* </IconButton> */}

                <Button variant="outlined" size="medium" color="primary" startIcon={<QrCode2Icon />} onClick={ () => setShowQRCode(true) }>
                    <b>Show QR code</b>
                </Button>

                {/* <IconButton size="medium" color="primary" onClick={ () => setShowQRCode(true) }> */}
                {/*     <QrCode2Icon /> */}
                {/* </IconButton> */}
                {/*  */}
                {/* <Button variant="outlined" size="medium" color="primary" */}
                {/*     startIcon={<GroupAddIcon />} */}
                {/* > */}
                {/*     <b>Copy invitation link</b> */}
                {/* </Button> */}
            </Box>
        </>
    )
}

const Room = () => {
    return (
        <Box>
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: { xs: 14, sm: 20 },
                    pb: { xs: 8, sm: 12 },
                }}
            >
                <Loading>
                    { room => (
                        <RoomProvider room={ room }>
                            <Round/>
                        </RoomProvider>
                    )}
                </Loading>
            </Container>
        </Box>
    )
}

export const route = {
    path: ":roomId",
    loader,
    element: <Room/>,
}
